import React from 'react';
import PropTypes from 'prop-types';
import List from '../../general/List';
import ExportReportButton from './reportList/ExportReportButton';
import {ReportDefinitions} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import DisplayReportButton from './reportList/DisplayReportButton';
import EditReportButton from './reportList/EditReportButton';
import RemoveReportButton from './reportList/RemoveReportButton';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../../constants/Rights';
import CopyReportButton from './reportList/CopyReportButton';

/**
 * @fero
 */

class ReportsList extends React.PureComponent {
    static propTypes = {
        onReportSelect: PropTypes.func.isRequired,
        selectedReportId: PropTypes.number,
        reports: ReportDefinitions.isRequired,
        reloadReports: PropTypes.func.isRequired,
    };

    render() {
        const {reports, selectedReportId, onReportSelect, reloadReports} = this.props;
        if (reports.length != null && reports.length > 0) {
            return <List
                onItemClick={(report) => {
                    onReportSelect(report.id)
                }}
                values={reports}
                selectedValueId={selectedReportId}
            >
                {reports.map((report) => {
                    return <div key={report.id} className="d-flex align-items-center">
                        <span className="mr-1">{report.name}</span>
                        { selectedReportId == report.id ? 
                            <div className="d-flex align-items-center">
                                <DisplayReportButton
                                    reportId={report.id}
                                    size="small"
                                />
                                <ExportReportButton
                                    className="ml-1"
                                    reportId={report.id}
                                    size="small"
                                />
                                <RightsWrapper from={RIGHTS.MANAGER}>
                                    <EditReportButton
                                        className="mx-1"
                                        reportId={report.id}
                                        size="small"
                                    />
                                    <CopyReportButton
                                        className="mx-1"
                                        reportId={report.id}
                                        size="small"
                                    />
                                    <RemoveReportButton
                                        reportId={report.id}
                                        size="small"
                                        onFinish={() => {
                                            onReportSelect(undefined);
                                            reloadReports();
                                        }}
                                    />
                                </RightsWrapper>
                            </div> : null
                        }
                    </div>;
                })}
            </List>;
        } else {
            return <h2 className="m-3 color-disabled text-center">
                <Trans>Nemáte uložené žiadne reporty.</Trans>
            </h2>;
        }
    }

}

export default ReportsList;

